//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { checkoutStep3, checkoutOptionStep2, } from '@/services/gtmManager';
// this is a wrapper function to get the Recurly token. Using a promise because that
// allows the other Vue state and store processes to run properly and no data is lost
function getToken (recurly, form) {
  return new Promise((resolve, reject) => {
    recurly.token(form, (err, token) => {
      if (err) {
        reject(err);
      } else {
        resolve(token.id);
      }
    });
  });
}

export default {
  name: 'BillingInfo',
  props: {
    variant: {
      type: String,
      default: 'A',
    },
    value: {
      type: Object,
      required: true,
    },
    account: {
      type: Object,
      required: true,
    },
    termsAndConditions: {
      type: String,
      required: true,
    },
    noCheckoutErrors: {
      type: Boolean,
      required: true,
    },
    skipPayment: {
      type: Boolean,
      required: true,
    },
    cartLoading: {
      type: Boolean,
      required: true,
    },
    sglCheckout: {
      type: Boolean,
      default: false,
    },
    courseState: {
      type: Array,
      default: () => [],
    },
    blockCheckout: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      sameAsAccount: true,
      selectedState: {},
      elements: {},
      recurly: {},
      localBilling: { ...this.value },
      checkoutErrorMessage: {
        type: String,
        default: '',
      },
    };
  },
  head () {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'https://js.recurly.com/v4/recurly.css',
        },
      ],
      script: [
        {
          src: 'https://js.recurly.com/v4/recurly.js',
          defer: true,
          // Changed after script load
          callback: () => {
            this.isRecurlyLoaded = true;
          },
        },
      ],
    };
  },
  computed: {
    showLiveAlert () {
      if (this.$auth.loggedIn && this.courseState === this.$auth.user.state) {
        return false;
      } else if (!this.$auth.loggedIn && this.$store.state.search.selected.state.length < 1) {
        return false;
      } else if (!this.$auth.loggedIn && this.$store.state.search.selected.state.length > 0) {
        const findState = this.$store.state.search.selected.state.filter(state => state.id === this.courseState);
        return findState.length < 1;
      } else {
        return true;
      }
    },
  },
  watch: {
    localBilling: {
      handler (newBilling) {
        this.$emit('input', { ...newBilling });
      },
      deep: true,
    },
    account: {
      handler (newAccount) {
        if (this.sameAsAccount) {
          this.localBilling = { ...newAccount };
        }
      },
      deep: true,
    },
    sameAsAccount () {
      this.localBilling = { ...this.account };
    },
    recurly () {
      this.recurly.on('change', this.changeHandler);
    },
  },
  mounted () {
    window.recurly.configure({
      publicKey: process.env.RECURLY_TOKEN,
      fields: {
        card: {
          inputType: 'mobileSelect',
          style: {
            fontColor: '#010101',
          },
        },
      },
    });
    // TODO: check if i need to change the recurly key before deployment this was causing error
    // need to store the `window.recurly` to the data because when navigating from
    // different pages back to the cart, the configured Recurly data is lost.
    // window.recurly.configure(process.env.PUBLIC_RECURLY_KEY);
    this.recurly = window.recurly;
  },
  methods: {
    includesAutoRenewal () {
      const sglCode = this.$route.params.slug.toUpperCase();
      const sglCodes = [
        'SGLAAHNATHLTHCARECORP',
        'SGLVAHNATHLTHCARECORP',
        'SGLOAHNATHLTHCARECORP',
      ];
      return !sglCodes.includes(sglCode);
    },
    async submit () {
      try {
        const token = this.skipPayment === true ? 'freeWebinar' : await getToken(this.recurly, this.$refs.checkoutForm);
        this.$emit('submit', token);
        // front-end recurly errors get caught here
      } catch (e) {
        this.loading = false;
        this.$emit('loading', false);
        // todo put in error message
        // passing recurly errors to cart/index to be handled in purchaseError()
        this.$emit('purchaseError', e.fields);
        this.checkoutErrorMessage = [];
        const fieldList = e.fields;
        for (let i = 0; i < fieldList.length; i++) {
          const current = fieldList[i];
          switch (current) {
            case 'cvv':
              this.checkoutErrorMessage.push('CVV must be three digits');
              continue;
            case 'number':
              this.checkoutErrorMessage.push('Invalid CC number or incorrect length');
              continue;
            case 'month':
              this.checkoutErrorMessage.push('Invalid month');
              continue;
            case 'year':
              this.checkoutErrorMessage.push('Invalid year');
              break;
          }
        }
        console.log('error =', e);
      }
    },
    checkChange (e) {
      this.sameAsAccount = e.target.checked;
      if (this.sameAsAccount === false) {
        try {
          checkoutOptionStep2(this.$gtm);
        } catch (e) {
          console.log(e);
        }
      }
    },
    changeHandler (state) {
      if (state.fields.card.valid === true) {
        try {
          checkoutStep3(this.$gtm);
        } catch (e) {
          console.log(e);
        }
        this.recurly.off('change', this.changeHandler);
      }
    },
    emitRecaptchaSuccess (token) {
      this.$emit('recaptchaVerified', token);
    },
  },
};
