import { DateTimeFormatter, LocalDateTime, ZonedDateTime, ZoneId, } from '@js-joda/core';
import { Locale, } from '@js-joda/locale_en-us';

export default {

  // On Chrome/Edge this will pass. The window.navigator.userAgent contains all browser names + versions
  isSafari () {
    return process.server || window.navigator.userAgent.toLowerCase().includes('safari');
  },
  isIphone () {
    return window.navigator.userAgent.toLowerCase().includes('iphone');
  },
  isSafariIphone () {
    const ua = window.navigator.userAgent.toLowerCase();
    console.log('iPhone check', ua);
    return ua.includes('iphone') && ua.includes('safari');
  },
  getUserAgent () {
    return window.navigator.userAgent.toLowerCase().split('').join('');
  },
  // make sure date needs comes in as a string for the .replace
  formatBrowserDate (date, options) {
    if (this.isSafari()) {
      return new Date(date.replace(/-/g, '/')).toLocaleDateString('en', options);
    } else {
      return new Date(date).toLocaleDateString('en', options);
    }
  },

  // pattern must be the format of the dateTime passed in
  formatUTCasLocalDateTime (date, pattern) {
    // convert UTC date time to timezone of client
    const localTime = ZonedDateTime.of(
      LocalDateTime.parse(date, DateTimeFormatter.ofPattern(pattern).withLocale(Locale.US)),
      ZoneId.of(ZoneId.systemDefault().toString())
    ).toLocalDateTime();

    // convert to javascript date
    const d = new Date(localTime.toString().replace(/\[|\]/g, '')).toString();

    // ~*beautify*~
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    // handle safari, and split the offset
    if (this.isSafari()) {
      return new Date(d.replace(/-/g, '/')).toLocaleDateString('en', options);
    } else {
      return new Date(d.split('-')).toLocaleDateString('en-US', options);
    }
  },
};
