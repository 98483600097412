import { DateTimeFormatter, LocalDate, LocalTime, ZonedDateTime, ZoneId, LocalDateTime, } from '@js-joda/core';
import dateFormatUtil from '@/utils/DateFormatterUtil.js';
const { Locale } = require('@js-joda/locale_en-us');

export const getFormat = (course) => {
  const formatMap = {
    'VIRTUAL': 'LiveStreaming',
    'LIVE': 'Live',
    'WEBINAR': 'Webinar',
    'PODCAST': 'Podcast',
    'VIDEO': 'Video',
    'TEXT': 'Text',
  };

  for (const [key, value] of Object.entries(formatMap)) {
    if (course.productTypeName.startsWith(key)) {
      return value;
    }
  }
  return 'Live';
};

export const isToday = (sessionDate) => {
  const today = LocalDate.now();
  const courseDate = LocalDate.parse(sessionDate);
  return today.isEqual(courseDate);
};

export const formatTime = (date) => {
  return date.substring(10).trim();
};

export const formatDate = (date) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date ? dateFormatUtil.formatBrowserDate(date.toString(), options) : '';
};

export const formatSessionDate = (date) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(date).toLocaleDateString('en', options);
};

export const getSessionDate = (session) => {
  const d = LocalDate.parse(session);
  const date = d.format(DateTimeFormatter.ofPattern('MMM dd, yyyy').withLocale(Locale.US));
  return (date);
};

export const getTimezone = (timezone) => {
  if (timezone === 'Central') {
    return 'America/Chicago';
  } else if (timezone === 'Pacific') {
    return 'America/Los_Angeles';
  } else if (timezone === 'Mountain') {
    return 'America/Denver';
  } else {
    return 'America/New_York';
  }
};

export const getSessionTime = (time) => {
  const date = LocalTime.parse(time, DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss').withLocale(Locale.US));
  const formattedDate = date.format(DateTimeFormatter.ofPattern('hh:mm a').withLocale(Locale.US));
  return (formattedDate);
};

export const validateEmail = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const showSignIn = (start, timezone) => {
  const zonedTime = ZonedDateTime.now(ZoneId.of(`${timezone}`)).format(DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss').withLocale(Locale.US));
  const currentTime = LocalDateTime.parse(zonedTime, DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss').withLocale(Locale.US));
  const courseStart = LocalDateTime.parse(start, DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss').withLocale(Locale.US));
  const startTime = courseStart.minusHours(1);
  const endTime = courseStart.plusMinutes(15);
  if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
    return true;
  } else {
    return false;
  }
};

export const showSignOut = (end, timezone) => {
  const zonedTime = ZonedDateTime.now(ZoneId.of(`${timezone}`)).format(DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss').withLocale(Locale.US));
  const currentTime = LocalDateTime.parse(zonedTime, DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss').withLocale(Locale.US));
  const courseEnd = LocalDateTime.parse(end, DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss').withLocale(Locale.US));
  const startTime = courseEnd.minusMinutes(15);
  const endTime = courseEnd.plusHours(1);
  if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
    return true;
  } else {
    return false;
  }
};

export const scrollToTab = (id) => {
  document.querySelector(`${id}`).scrollIntoView({ behavior: 'smooth' });
};
