//
//
//
//
//
//
//
//

export default {
  name: 'Recaptcha',
  data () {
    return {
      siteKey: process.env.RECAPTCHA_SITE_KEY,
    };
  },
  head () {
    return {
      script: [
        {
          src: 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit',
          async: true,
          defer: true,
        },
      ],
    };
  },
  mounted () {
    console.log('INSIDE MOUNTED!!');
    // Ensure the script is loaded
    window.onRecaptchaLoad = () => {
      // Render the recaptcha widget
      window.grecaptcha.render(this.$refs.recaptcha, {
        sitekey: this.siteKey,
        callback: this.approved,
      });
    };
  },
  methods: {
    approved (responseToken) {
      console.log('INSIDE APPROVED!!!');
      console.log('Recaptcha Token Received:', responseToken);
      this.$emit('recaptchaSuccess', responseToken);
    },
  },
};
