//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, } from 'vuex';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import { checkoutComplete, } from '@/services/iterable';
import { checkoutStep1, eCommerceNull, addHep, } from '@/services/gtmManager';
import { validateEmail, } from '@/utils/reusedFunctions';

export default {
  name: 'CartIndex',
  data () {
    return {
      isHepOnlyItemInCart: false,
      isHepInCart: false,
      showHepModal: false,
      radioValue: false,
      trueValue: true,
      variant: 'B',
      revealDiscount: false,
      numDiscountCode: 0,
      discountCodeInput: '',
      selectedState: {},
      isRecurlyLoaded: false,
      loading: false,
      recaptchaToken: '',
      loadedUser: {
        firstName: '',
        lastName: '',
        email: '',
        profession: '',
        title: '',
        company: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
      },
      cartLoading: false,
      isFormValid: {
        type: Boolean,
        default: false,
      },
      missingFields: {
        type: Array,
        default: () => [],
      },
      secondaryError: {
        type: String,
        default: () => '',
      },
      recaptchaVerified: process.env.RECAPTCHA_BYPASS_LOCAL ?? false,
    };
  },
  head () {
    return {
      title: 'Checkout',
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: 'Checkout',
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: 'Secure online checkout for Summit Subscription or individual CE courses.',
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Secure online checkout for Summit Subscription or individual CE courses.',
        },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://js.recurly.com/v4/recurly.css',
        },
      ],
      script: [
        {
          src: 'https://js.recurly.com/v4/recurly.js',
          defer: true,
          // Changed after script load
          callback: () => {
            this.isRecurlyLoaded = true;
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('cart', [
      'cartItems',
      'cartCount',
      'discountCodes',
      'attendee',
      'subTotal',
      'discountCodes',
      'discountTotal',
      'estimatedTax',
      'total',
      'billing',
      'account',
      'cartItemNames',
    ]),
    getCartItemStates () {
      return this.cartItems
        .filter(item => Object.keys(item).includes('productType') &&
          item.productType &&
          item.productType.startsWith('LIVE'))
        .map(item => item.state);
    },
    isChecked () {
      return this.radioValue;
    },
  },
  watch: {
    /* Page refresh causes vue to lose cart state, causing cart count to be 0. CartLoading
    is to display a loading message   while we load cart from LocalStorage and persist the cart data
    * */
    cartCount: {
      handler () {
        const cart = LocalStorageUtil.get('cart');
        this.cartLoading = (typeof cart === 'undefined');
      },
      immediate: true,
    },
    account: {
      handler () {
        if (this.missingFields.length > 0 || this.secondaryError.length > 0) { this.checkForm(); }
      },
    },
    cartItemNames: {
      handler () {
        this.isHepOnlyItemInCart = (this.cartItems.length === 1 && this.cartItemNames[0].includes('HEP'));
        this.isHepInCart = this.cartItemNames.filter(item => item.includes('HEP')).length > 0;
        this.radioValue = this.isHepInCart;
      },
      immediate: true,
    },
  },
  created () {
    this.refreshCart();
  },
  mounted (error) {
    if (window.recurly) {
      this.isRecurlyLoaded = true;
    }
    if (this.$route.params.cart === 'form_cartadditem.jsp') {
      this.addItem({ id: this.$route.query.sessionId });
      this.addDiscount(this.$route.query.discountCode);
    } else if (this.$route.params.cart !== 'cart') {
      error('something happened');
    }
    if (this.$auth.loggedIn) {
      this.loadedUser.firstName = this.$auth.user.first_name;
      this.loadedUser.lastName = this.$auth.user.last_name;
      this.loadedUser.email = this.$auth.user.email;
      this.loadedUser.profession = { id: this.$auth.user.title_code, type: 'profession' };
      this.loadedUser.title = this.$auth.user.title;
      this.loadedUser.company = this.$auth.user.company;
      this.loadedUser.phone = this.$auth.user.mobile_number;
      this.loadedUser.address1 = this.$auth.user.address1;
      this.loadedUser.address2 = this.$auth.user.address2;
      this.loadedUser.city = this.$auth.user.city;
      this.loadedUser.state = { id: this.$auth.user.state };
      this.loadedUser.zipcode = this.$auth.user.zip_code;
      this.currentAccount(this.loadedUser);
      this.updateBilling(this.loadedUser);
      this.taxUpdate();
    }
    // GTM trackCartTotal from old site
    const productsList = [];
    (this.cartItems).forEach((item) => {
      const cartItem = item.sessionId ?? item.id;
      const itemType = cartItem.startsWith('SUB') ? 'SUBSCRIPTION' : 'COURSE';
      productsList.push({
        id: item.sessionId,
        name: item.title,
        category: itemType,
        variant: item.productType,
        brand: 'Summit',
        quantity: 1,
        price: item.price,
      });
    });
    try {
      if (this.cartItems.length > 0) {
        console.log('cart > 0');
        eCommerceNull(this.$gtm);
        checkoutStep1(this.$gtm, productsList, this.$auth);
      }
    } catch (e) {
      console.log('GTM ERROR: ' + JSON.stringify(e));
    }
  },
  methods: {
    ...mapActions('cart', [
      'removeItem',
      'addItem',
      'addDiscount',
      'removeDiscount',
      'checkout',
      'updateBilling',
      'updateAccount',
      'currentAccount',
      'updateTax',
      'updateCart',
    ]),
    ...mapMutations('cart', ['clear', 'refreshCart']),
    validateEmail,
    extractNumber (str) {
      const thenum = str.substr(str.indexOf('_') + 1);
      return thenum;
    },
    toggleRadio () {
      // Toggle the radio value
      this.radioValue = !this.radioValue;
    },
    // TODO: Fix this to actually add hep to cart
    addHepToCart () {
      if (this.radioValue || this.showHepModal) {
        this.addItem({
          'id': 'SUBHEP_MONTHLY',
          'sessionId': 'SUBHEP_MONTHLY',
          'courseId': 'SUBSCRIPTION',
          'productType': 'SUBHEP_MONTHLY',
          'price': 9.99,
          'attendeeCount': 0,
          'attendees': [],
        });
        addHep(this.$gtm);
      } else {
        this.removeItem('SUBHEP_MONTHLY');
      }
      console.log('Radio value changed:', this.radioValue);
    },
    async applyDiscount () {
      try {
        this.numDiscountCode = this.discountCodes.length;
        await this.addDiscount(this.discountCodeInput);
        this.discountCodeInput = '';
      } catch (e) {
        this.secondaryError = 'Error applying discount.' + e;
      } finally {
        this.secondaryError = '';
        if (this.discountCodes[this.numDiscountCode] === undefined) {
          this.secondaryError = 'Invalid discount code';
        }
      }
    },
    // uses recurly's `fields` array from exception in BillingInfo
    handlePurchaseError (fields) {
      this.missingFields = [];
      const fieldList = fields;
      for (let i = 0; i < fieldList.length; i++) {
        const current = fieldList[i];
        switch (current) {
          case 'first_name' :
            this.missingFields.push('First name');
            continue;
          case 'last_name' :
            this.missingFields.push('Last name');
            continue;
          case 'address1' :
            this.missingFields.push('Address 1');
            continue;
          case 'city':
            this.missingFields.push('City');
            continue;
          case 'state':
            this.missingFields.push('State');
            continue;
          case 'postal_code':
            this.missingFields.push('Zipcode (5 numeric digits required)');
            break;
        }
      }
      this.checkForm();
    },
    // checks the account state objects for values
    checkForm () {
      this.secondaryError = '';
      this.missingFields = [];
      if (!this.$auth.user) {
        if (this.isEmpty(this.account.email)) {
          this.missingFields.push('Email');
        } if (this.account.email) {
          if (!this.validateEmail(this.account.email)) {
            this.secondaryError = 'Invalid email address';
          }
        } if (typeof this.account.profession === 'undefined') {
          this.missingFields.push('Profession');
        } if (this.isEmpty(this.account.title)) {
          this.missingFields.push('Job title');
        } if (this.account.phone === undefined || this.isEmpty(this.account.phone)) {
          this.missingFields.push('Phone');
        }
        if (this.account.address1 === undefined || this.isEmpty(this.account.address1)) {
          this.missingFields.push('Address');
        }
        if (this.account.city === undefined || this.isEmpty(this.account.city)) {
          this.missingFields.push('City');
        }
        if (this.account.state === undefined || this.isEmpty(this.account.state)) {
          this.missingFields.push('State');
        }
        if (this.account.zipcode === undefined || this.isEmpty(this.account.zipcode)) {
          this.missingFields.push('Zipcode');
        }
        if ((this.account.company === undefined || this.isEmpty(this.account.company))) {
          this.missingFields.push('Company Name');
        }
        this.markInvalidFields();
        return this.missingFields.length === 0 && this.secondaryError.length === 0;
      } else {
        return true;
      }
    },
    isEmpty (value) {
      return value === '' || typeof value === 'undefined';
    },
    getProductType (item) {
      if (item.startsWith('LIVE')) {
        return 'LIVE';
      } else if (item.startsWith('WEBINAR')) {
        return 'WEBINAR';
      } else if (item.startsWith('VIRTUAL')) {
        return 'LIVESTREAM';
      } else if (item.startsWith('VIDEO')) {
        return 'VIDEO';
      } else if (item.startsWith('TEXT')) {
        return 'TEXT';
      } else if (item.startsWith('PODCAST')) {
        return 'PODCAST';
      }
    },
    getTitle (cartItem) {
      // TODO: this should be somewhere else
      switch (cartItem.sessionId) {
        case 'SUBONLINE': return 'Online Access Subscription';
        case 'SUBVIRTUAL': return 'Virtual Access Subscription';
        case 'SUBALLACCESS': return 'All Access Subscription';
        case 'SUBCORE100_PT': return 'Core 100 PT Subscription';
        case 'SUBCORE100_OT': return 'Core 100 OT Subscription';
        case 'SUBCORE100_SLP': return 'Core 100 SLP Subscription';
        case 'SUBCORE100PLUS_PT': return 'Core 100 Plus PT Subscription';
        case 'SUBCORE100PLUS_OT': return 'Core 100 Plus OT Subscription';
        case 'SUBCORE100PLUS_SLP': return 'Core 100 Plus SLP Subscription';
        case 'SUBHEP_MONTHLY': return 'Summit HEP (Monthly)';
        default: return cartItem.title;
      }
    },
    tcTypeCheck (cartItems) {
      for (const items of cartItems) {
        if (items.productType === 'LIVE') {
          console.log(items.productType.toString());
          return items.productType.toString();
        } if (items.title === 'Summit Subscription') {
          return 'Subscription';
        }
      }
      return 'noT&C';
    },
    rotateIcon () {
      const el = document.getElementById('discount-icon');
      el.classList.toggle('fa-rotate-90');
    },
    updateLoading (loading) {
      this.loading = loading;
    },
    async subscriptionRedirect () {
      await this.$router.push('/subscription/subscription-v2');
    },
    markInvalidFields () {
      const selectFieldNames = [
        'account-professions-select',
        'state-select',
      ];
      const inputFieldNames = [
        'first-name',
        'last-name',
        'email',
        'title',
        'phone',
        'address1',
        'city',
        'zipcode',
        'company',
      ];
      inputFieldNames.forEach((name) => {
        const element = document.getElementById(name);
        if (!element.checkValidity()) {
          element.style.cssText = 'border-color: salmon;background-color: #ffeded;';
        }
      });
      selectFieldNames.forEach((name) => {
        const element = document.getElementById(name);
        if (element.value.length === 0) {
          element.style.cssText = 'border-color: salmon;background-color: #ffeded;';
        } else {
          element.style.cssText = 'border-color: limegreen; background-color: #e7ffe7;';
        }
      });
    },
    async taxUpdate () {
      await this.updateTax().catch((e) => {
        /[a-zA-Z]/.test(this.account.zipcode)
          ? this.secondaryError = 'Zipcode cannot contain letters'
          : this.secondaryError = e.message.toString().split(':').slice(1).join(':');
      });
    },
    async doCheckout (token) {
      if (this.checkForm() === true) {
        this.missingFields = [];
        try {
          this.loading = true;
          const recaptchaToken = this.recaptchaToken;
          const oldCartData = await this.checkout({ token, recaptchaToken });
          checkoutComplete(oldCartData, this.$cookies);
          await this.$router.push({
            path: '/cart/confirmation',
            // passing it as query data because we empty the cart on success and we want to still show some of the data on the `complete` page
            query: { data: JSON.stringify(oldCartData) },
          });
          // recurly API error's get caught here
        } catch (e) {
          this.loading = false;
          // todo: make a checkout error message
          //       the `e` has different error types, so those should probably be handled in some way.
          // removing up to and including the first colon
          console.log(e);
          this.secondaryError = (e.message).toString().includes(':') ? e.message.toString().split(':').slice(1).join(':') : e.message.toString();
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.secondaryError = 'Please complete the account form';
      }
    },
    setRecaptchaVerified (token) {
      this.recaptchaToken = token;
      this.recaptchaVerified = true;
      this.secondaryError = '';
      this.missingFields = [];
    },
  },
};
